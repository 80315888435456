import {Box, Divider, Drawer, Typography, useMediaQuery} from "@mui/material";
import Logo from "../../assets/logo.svg";
import DotyLogo from "../../assets/doty-logo.svg";
import {getColor} from "@/utils/style";
import {useTranslation} from "react-i18next";
import SelectLanguage from "../SelectLanguage";
import SelectMagazine from '@/components/Landing/SelectMagazine';
import Link from "next/link";
import {PermIdentityOutlined, Search as SearchIcon, Menu as MenuIcon} from "@mui/icons-material";
import Search from "./Search";
import {useState} from "react";
import SubscribeDialog from "../SubscribeDialog";
import {useAuth} from "@/contexts/authContext";
import Button from "../shared/Button";
import HamburgerSidebar from "./HamburgerSidebar";
import NonUserSubscribeDialog from "../NonUserSubscribeDialog/NonUserSubscribeDialog";
import {useRecoilState} from 'recoil';
import {selectedIndexState} from '@/atoms/selectedIndexState';

export default function Header() {
    const lgMatch = useMediaQuery((theme) => (theme as any).breakpoints.down("md"), {noSsr: true});
    const {t} = useTranslation();
    const {isLoggedIn, logout, info} = useAuth();
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useRecoilState(selectedIndexState);

    return (
        <>
            <Box
                width="100%"
                height="80px"
                minHeight="80px"
                maxHeight="80px"
                bgcolor="white"
                display="flex"
                justifyContent="center"
                position={isMenuOpen ? "fixed" : "relative"}
                zIndex="1300"
                flex={1}
                sx={{
                    minHeight: {xs: "60px", sm: "80px"},
                    maxHeight: {xs: "60px", sm: "80px"},
                    height: {xs: "60px", sm: "80px"},
                    // zIndex: 9000
                }}
            >
                <Box
                    width="1300px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        width: {xs: "100%", lg: "1300px"},
                        px: {xs: "16px", lg: 0},
                    }}
                >
                    <Link href="/" passHref>
                        <Logo viewBox="0 0 150 21"/>
                    </Link>
                    <Box display="flex" alignItems="center" gap="2.4rem">
                        <Box
                            display="flex"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                setIsMenuOpen(false);
                                setIsSearchOpen(!isSearchOpen);
                            }}
                        >
                            <SearchIcon sx={{fontSize: "24px", color: "black"}}/>
                        </Box>
                        {lgMatch ? (
                            <>
                                <Button
                                    onClick={() => {
                                        setIsSearchOpen(false);
                                        setIsMenuOpen(!isMenuOpen);
                                    }}
                                >
                                    <MenuIcon sx={{fontSize: "24px", color: "black"}}/>
                                </Button>
                                <Drawer anchor="right" open={isMenuOpen && lgMatch}
                                        onClose={() => setIsMenuOpen(false)}>
                                    <HamburgerSidebar setIsSubscribeOpen={setIsSubscribeOpen}
                                                      close={() => setIsMenuOpen(false)}/>
                                </Drawer>
                            </>
                        ) : (
                            <Box display="flex" alignItems="center" gap="2.8rem">
                                {isLoggedIn ? (
                                    <>
                                        {/* {!info?.subscribe && (
                      <Box
                        py="8px"
                        px="14px"
                        borderRadius="4px"
                        bgcolor={info?.subscribe ? getColor("marineBlue") : "#e6eaef"}
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsSubscribeOpen(true)}
                      >
                        <Typography
                          fontSize="1.6rem"
                          color={info?.subscribe ? "white" : getColor("marineBlue")}
                          fontWeight="500"
                        >
                          {t("subscribe")}
                        </Typography>
                      </Box>
                    )} */}
                                        <Link href="/mypage" passHref>
                                            <Box display="flex" alignItems="center" gap="0.6rem">
                                                <PermIdentityOutlined sx={{fontSize: "24px", color: "black"}}/>
                                                <Typography color={getColor("gray700")} fontSize="1.6rem">
                                                    {info?.nickname}
                                                </Typography>
                                            </Box>
                                        </Link>
                                        <Divider orientation="vertical" flexItem
                                                 style={{borderColor: getColor("gray500")}}/>
                                        <Typography
                                            color={getColor("gray700")}
                                            fontSize="1.6rem"
                                            style={{cursor: "pointer"}}
                                            onClick={logout}
                                        >
                                            {t("logout")}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Box
                                            py="8px"
                                            px="14px"
                                            borderRadius="4px"
                                            bgcolor={info?.subscribe ? getColor("marineBlue") : "#e6eaef"}
                                            style={{cursor: "pointer"}}
                                            onClick={() => setIsSubscribeOpen(true)}
                                        >
                                            <Typography
                                                fontSize="1.6rem"
                                                color={info?.subscribe ? "white" : getColor("marineBlue")}
                                                fontWeight="500"
                                            >
                                                {t("subscribe")}
                                            </Typography>
                                        </Box>
                                        {/*<Link href="https://hyundai-doty.com/" passHref replace target={"_blank"}>*/}
                                        {/*    <DotyLogo width="30" height="auto"/>*/}
                                        {/*</Link>*/}
                                        {/* <Link href="/signin">
                      <Typography color={getColor("gray700")} fontSize="1.6rem">
                        {t("login")}
                      </Typography>
                    </Link> */}
                                        {/* <Divider orientation="vertical" flexItem style={{ borderColor: getColor("gray500") }} />
                    <Link href="/signup">
                      <Typography color={getColor("gray700")} fontSize="1.6rem">
                        {t("joinUs")}
                      </Typography>
                    </Link> */}
                                    </>
                                )}
                                <Divider orientation="vertical" flexItem style={{borderColor: getColor("gray500")}}/>
                                <SelectLanguage/>
                                <Divider orientation="vertical" flexItem style={{borderColor: getColor("gray500")}}/>
                                <SelectMagazine selectedIndex={selectedIndex}
                                                setSelectedIndex={(index) => setSelectedIndex(index)}/>
                            </Box>
                        )}
                    </Box>
                </Box>
                {isSearchOpen && <Search close={() => setIsSearchOpen(false)}/>}
            </Box>
            <NonUserSubscribeDialog open={isSubscribeOpen} onClose={() => setIsSubscribeOpen(false)}/>
            {isMenuOpen && lgMatch && <Box height="80px" minHeight="80px" maxHeight="80px"/>}
        </>
    );
}
