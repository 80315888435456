import { ComponentProps } from "react";
import MButton, { ButtonProps } from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import { getColor } from "@/utils/style";

const CustomButton = styled(MButton)<ButtonProps>(({ theme }) => ({
  minWidth: "0",
  borderRadius: 0,
  boxShadow: "none",
  color: getColor("black"),
  padding: "8px",
  whiteSpace: "nowrap",
  "&:hover": {
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "none",
  },
}));

export default function Button(props: ButtonProps) {
  return <CustomButton {...props} disableFocusRipple></CustomButton>;
}
