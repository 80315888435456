import { useTranslation } from "react-i18next";
import LocalStorage from "@/utils/localstorage";
import { Box, Button, Divider } from '@mui/material';

export default function SelectLanguage() {
    const { i18n } = useTranslation();

    const changeLanguage = (value: string) => {
        i18n?.changeLanguage(value);
        LocalStorage.setItem("hmc_language", value);
    };

    return (
        <Box display="flex" >
            <Button
                sx={{ color: i18n.language === 'en' ? '#002c5f' : '#999999', fontSize: '19px',
                    minWidth: 'auto'}}
                disableRipple
                onClick={() => changeLanguage('en')}
            >
                EN
            </Button>
            <Divider orientation="vertical" flexItem sx={{ borderColor: '#999999' }} variant="middle"/>
            <Button
                sx={{
                    color: i18n.language === 'es' ?
                        '#002c5f'
                        :
                        '#999999',
                    fontSize: '19px',
                    minWidth: 'auto'
                }}
                disableRipple
                onClick={() => changeLanguage('es')}
            >
                ES
            </Button>
        </Box>
    );
};
