import { Box, Checkbox, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Input from "../shared/Input";
import { getColor } from "@/utils/style";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import Button from "../shared/Button";
import Select from "../shared/Select";
import { instance } from "@/apis";
import { useAlert } from "@/contexts/alertContext";
import { useEffect, useState } from "react";

interface Props {
  submit: () => void;
  changePage: () => void;
}

interface Forms {
  email: string;
  code: string;
}

const getTypeMessage = (name: keyof Forms, type: string | undefined, t: TFunction) => {
  if (!type) return undefined;

  switch (type) {
    case "required":
      return t("require");
    case "pattern":
      switch (name) {
        case "email":
          return t("email-format-is-incorrect");
      }
    case "value":
      switch (name) {
      }
    case "minLength":
      switch (name) {
      }
    case "maxLength":
      switch (name) {
        case "email":
          return t("must-be-no-more-than-128-characters");
      }
    case "duplicated":
      switch (name) {
        case "email":
          return t("email-is-duplicated");
      }
    case "validate":
      switch (name) {
        case "email":
          return t("your-email-is-not-subscribed");
      }
  }
};

export default function SubscribeForm({ submit, changePage }: Props) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    watch,
    trigger,
    formState: { errors },
  } = useForm<Forms>();
  const { showAlert } = useAlert();
  const [isClickedEmail, setIsClickedEmail] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isValidateMessage, setIsValidateMessage] = useState("");

  const validate = async () => {
    try {
      const { email, code } = getValues();
      await instance.get(`/subscriber/code/verification?email=${email}&code=${code}&type=unsubscribe`);
      setIsValidate(true);
      setIsValidateMessage("");
    } catch (e) {
      setIsValidateMessage(t("the-code-is-incorrect") ?? "");
      setIsValidate(false);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "code") {
        setIsValidateMessage("");
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const subscribe = async (data: Forms) => {
    // if (!isValidate) {
    //   setIsValidateMessage(t("verification-is-required") ?? "");
    //   return;
    // }

    try {
      await instance.delete(`/subscriber/${data.email}`);
      submit();
    } catch (e) {}
  };

  const email = async () => {
    const { email } = getValues();

    if (!(await trigger("email"))) return;

    try {
      clearErrors("email");
      await instance.get(`/subscriber/code?email=${email}&type=unsubscribe`);
      showAlert({ title: t("check-your-email") ?? "", content: t("check-the-email-for-the-code") ?? "" });
      setIsClickedEmail(true);
    } catch (e) {
      setError("email", { type: "validate" });
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography fontSize="1.4rem">
          {t("email")}
          <span style={{ color: getColor("skyblue") }}>*</span>
        </Typography>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            maxLength: 128,
          }}
          render={({ field: { ref, ...field } }) => (
            <Input
              placeholder={t("email") ?? ""}
              {...field}
              value={field.value?.toLowerCase()}
              inputRef={ref}
              error={!!errors?.email}
              fullWidth
              helperText={getTypeMessage("email", errors.email?.type, t)}
              disabled={isClickedEmail}
              /* InputProps={{
                endAdornment: (
                  <Button
                    sx={{
                      background: "black",
                      borderRadius: "4px",
                      padding: "6px 0",
                      width: "100px",
                      "&:hover": { background: "black" },
                    }}
                    onClick={email}
                    disabled={isClickedEmail}
                  >
                    <Typography fontSize="1.2rem" color="white">
                      {t("verification")}
                    </Typography>
                  </Button>
                ),
              }} */
            />
          )}
        />
        {isClickedEmail && (
          <Typography mt="0.4rem" fontSize="1.4rem" color={getColor("skyblue")}>
            {t("verification-code-has-been-sent-to-your-email")}
          </Typography>
        )}
      </Box>
      {/* <Box display="flex" flexDirection="column">
        <Typography fontSize="1.4rem">
          {t("verification-code")}
          <span style={{ color: getColor("skyblue") }}>*</span>
        </Typography>
        <Controller
          name="code"
          control={control}
          rules={{ required: true, minLength: 5, maxLength: 30 }}
          render={({ field: { ref, ...field } }) => (
            <Input
              placeholder={t("verification-code") ?? ""}
              {...field}
              value={field.value?.toLowerCase()}
              inputRef={ref}
              error={!!errors?.email}
              fullWidth
              helperText={getTypeMessage("code", errors.code?.type, t)}
              disabled={isValidate}
              InputProps={{
                endAdornment: (
                  <Button
                    sx={{
                      background: "black",
                      borderRadius: "4px",
                      padding: "6px 0",
                      width: "100px",
                      "&:hover": { background: "black" },
                    }}
                    onClick={validate}
                  >
                    <Typography fontSize="1.2rem" color="white">
                      {t("check")}
                    </Typography>
                  </Button>
                ),
              }}
            />
          )}
        />
        {isValidate && (
          <Typography mt="0.4rem" fontSize="1.4rem" color={getColor("skyblue")}>
            {t("verification-completed")}
          </Typography>
        )}
        {isValidateMessage && (
          <Typography mt="0.4rem" fontSize="1.4rem" color="red">
            {isValidateMessage}
          </Typography>
        )}
      </Box> */}
      <Typography fontSize="1.8rem" fontWeight="500" color={getColor("gray700")} lineHeight="2.2rem">
        {t("are-you-sure-you-want-to-cancel-hyundai-newsletter-subscription")}
      </Typography>
      <Box display="flex" gap="2rem">
        <Button
          variant="contained"
          sx={{ width: "100%", py: "20px", mt: "20px", background: `${getColor("gray500")} !important` }}
          onClick={changePage}
        >
          <Typography fontSize="2.2rem" fontWeight="500" color="white">
            {t("back")}
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="marineBlue"
          sx={{ width: "100%", py: "20px", mt: "20px" }}
          onClick={handleSubmit(subscribe)}
        >
          <Typography fontSize="2.2rem" fontWeight="500" color="white">
            {t("unsubscribe")}
          </Typography>
        </Button>
      </Box>
    </>
  );
}
