import { getWebzine, getWebzines } from "@/apis/webzine";
import { useQuery } from "@tanstack/react-query";

export const useWebzinesQuery = () => {
  return useQuery({
    queryKey: ["webzine"],
    queryFn: getWebzines,
    staleTime: 3600,
  });
};

export const useWebzineQuery = (id: string) => {
  return useQuery({
    queryKey: ["webzine", id],
    queryFn: () => getWebzine(id),
  });
};
