import { ComponentProps } from "react";
import MInput, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { getColor } from "@/utils/style";

const CustomInput = styled(MInput)<TextFieldProps>(({ theme }) => ({
  "&::after": {
    display: "none",
  },
  "&::before": {
    display: "none",
  },
  "& > div": {
    borderRadius: "0",
    borderBottom: `1px solid ${getColor("gray500")}`,
  },
  "& input": {
    border: "none",
    fontSize: "2.2rem",
    padding: "1rem 1.6rem",
  },
  "& input:focus": {
    border: "none",
    // borderBottom: `1px solid black`,
  },
  ".Mui-error": {
    borderColor: getColor("red"),
  },
  ".Mui-error div": {
    borderColor: getColor("red"),
  },
  "& .MuiFormHelperText-root": {
    marginTop: "0.4rem",
    fontSize: "1.4rem",
  },
  "& fieldset": {
    border: "none",
  },
  "& fieldset:focus": {
    border: "none",
  },
}));

export default function Input({ ...props }: TextFieldProps) {
  return <CustomInput {...props}></CustomInput>;
}
