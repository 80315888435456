import { Box, Checkbox, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Input from "../shared/Input";
import { getColor } from "@/utils/style";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import Button from "../shared/Button";
import Select from "../shared/Select";
import { instance } from "@/apis";
import { useAlert } from "@/contexts/alertContext";
import { useEffect, useState } from "react";

const Props = {
  displayEmpty: true,
  MenuProps: {
    PaperProps: {
      sx: {
        width: "0",
        maxHeight: "400px",
        backgroundColor: getColor("ivory"),
        "& .MuiMenuItem-root": {
          backgroundColor: `${getColor("ivory")} !important`,
          color: getColor("black"),
          fontSize: "1.6rem",
          width: "100%",
          py: "1.4rem",
          px: "1.2rem",
        },
        "& .MuiMenuItem-root:hover": {
          color: "white",
          background: `${getColor("marineBlue")} !important`,
        },
        "& .Mui-selected": {
          color: "white",
          background: `${getColor("marineBlue")} !important`,
        },
        overflowX: "auto",
      },
    },
  },
  sx: {
    borderBottom: "1px solid black",
    px: "1.6rem",
    py: "1rem",
    fontSize: "2.2rem",
  },
};

interface Props {
  submit: () => void;
  changePage: () => void;
}

const languages = [
  "English",
  "Chinese",
  "Arabic",
  "French",
  "Spanish",
  "Indonesian",
  "Thai",
  "Vietnamese",
  "German",
  "Hungarian",
  "Czech",
];

const esLanguages = [
  "English",
  "Chinese",
  "Arabic",
  "French",
  "Spanish",
  "Indonesian",
  "Thai",
  "Vietnamese",
  "German",
  "Hungarian",
  "Czech",
];

interface Forms {
  firstName: string;
  lastName: string;
  language: (typeof languages)[number];
  email: string;
  code: string;
  chekced: boolean;
}

const getTypeMessage = (name: keyof Forms, type: string | undefined, t: TFunction) => {
  if (!type) return undefined;

  switch (type) {
    case "required":
      return t("require");
    case "pattern":
      switch (name) {
        case "email":
          return t("email-format-is-incorrect");
      }
    case "value":
      switch (name) {
      }
    case "minLength":
      switch (name) {
      }
    case "maxLength":
      switch (name) {
        case "firstName":
        case "lastName":
          return t("must-be-no-more-than-32-characters");
        case "email":
          return t("must-be-no-more-than-128-characters");
      }
    case "duplicated":
      switch (name) {
        case "email":
          return t("email-is-duplicated");
      }
    case "validate":
      switch (name) {
        case "email":
          return t("your-email-is-already-subscribed");
      }
  }
};

export default function SubscribeForm({ submit, changePage }: Props) {
  const { t, i18n: {language} } = useTranslation();
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    setError,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<Forms>({ defaultValues: { language: "" } });
  const { showAlert } = useAlert();
  const [isClickedEmail, setIsClickedEmail] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isValidateMessage, setIsValidateMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>('');

  const validate = async () => {
    try {
      const { email, code } = getValues();
      await instance.get(`/subscriber/code/verification?email=${email}&code=${code}&type=subscribe`);
      setIsValidate(true);
      setIsValidateMessage("");
    } catch (e) {
      setIsValidateMessage(t("the-code-is-incorrect") ?? "");
      setIsValidate(false);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "code") {
        setIsValidateMessage("");
      }

      if (name === "email") {
        setIsClickedEmail(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const subscribe = async (data: Forms) => {
    // if (!isValidate) {
    //   setIsValidateMessage(t("verification-is-required") ?? "");
    //   return;
    // }
    instance.post("/subscriber", { ...data, language: data.language.toLowerCase() })
      .then((value) => {
        submit();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          setErrorMessage('This email already registered')
        }
      })
  };

  const email = async () => {
    const { email } = getValues();

    if (!(await trigger("email"))) return;

    try {
      clearErrors("email");
      await instance.get(`/subscriber/code?email=${email}&type=subscribe`);
      showAlert({ title: t("check-your-email") ?? "", content: t("check-the-email-for-the-code") ?? "" });
      setIsClickedEmail(true);
    } catch (e) {
      setError("email", { type: "validate" });
    }
  };

  return (
    <>
      <Box width="100%" display="flex" gap="16px">
        <Box display="flex" flexDirection="column">
          <Typography fontSize="1.4rem">
            {t("first-name-0")}
            <span style={{ color: getColor("skyblue") }}>*</span>
          </Typography>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true, maxLength: 32 }}
            render={({ field: { ref, ...field } }) => (
              <Input
                placeholder={t("first-name") ?? ""}
                {...field}
                inputRef={ref}
                error={!!errors?.firstName}
                helperText={getTypeMessage("firstName", errors.firstName?.type, t)}
                fullWidth
              />
            )}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography fontSize="1.4rem">
            {t("last-name-0")}
            <span style={{ color: getColor("skyblue") }}>*</span>
          </Typography>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true, maxLength: 32 }}
            render={({ field: { ref, ...field } }) => (
              <Input
                placeholder={t("last-name") ?? ""}
                {...field}
                inputRef={ref}
                error={!!errors?.lastName}
                helperText={getTypeMessage("lastName", errors.lastName?.type, t)}
                fullWidth
              />
            )}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontSize="1.4rem">
          {t("language")}
          <span style={{ color: getColor("skyblue") }}>*</span>
        </Typography>
        <Controller
          name="language"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { ref, ...field } }) => (
            <Select
              {...Props}
              placeholder={t("language") ?? ""}
              {...field}
              inputRef={ref}
              options={[
                { value: "", label: t("select-language") ?? "", notDisplaying: true },
                ...(language === 'en' ? languages : esLanguages).map((language) => ({
                  value: language
                })),
              ]}
              error={!!errors?.language}
              helperText={getTypeMessage("language", errors.language?.type, t)}
            />
          )}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontSize="1.4rem">
          {t("email")}
          <span style={{ color: getColor("skyblue") }}>*</span>
        </Typography>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            maxLength: 128,
          }}
          render={({ field: { ref, ...field } }) => (
            <Input
              placeholder={t("email") ?? ""}
              {...field}
              value={field.value?.toLowerCase()}
              inputRef={ref}
              error={!!errors?.email}
              fullWidth
              helperText={getTypeMessage("email", errors.email?.type, t)}
              disabled={isClickedEmail}
              /* InputProps={{
                endAdornment: (
                  <Button
                    sx={{
                      background: "black",
                      borderRadius: "4px",
                      padding: "6px 0",
                      width: "100px",
                      "&:hover": { background: "black" },
                    }}
                    onClick={email}
                    disabled={isClickedEmail}
                  >
                    <Typography fontSize="1.2rem" color="white">
                      {t("verification")}
                    </Typography>
                  </Button>
                ),
              }} */
            />
          )}
        />
        {isClickedEmail && (
          <Typography mt="0.4rem" fontSize="1.4rem" color={getColor("skyblue")}>
            {t("verification-code-has-been-sent-to-your-email")}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        {errorMessage && (
          <Typography mt="0.4rem" fontSize="1.8rem" color="red">
            {errorMessage}
          </Typography>
        )}
      </Box>
      {/* <Box display="flex" flexDirection="column">
        <Typography fontSize="1.4rem">
          {t("verification-code")}
          <span style={{ color: getColor("skyblue") }}>*</span>
        </Typography>
        <Controller
          name="code"
          control={control}
          rules={{ required: true, minLength: 5, maxLength: 30 }}
          render={({ field: { ref, ...field } }) => (
            <Input
              placeholder={t("verification-code") ?? ""}
              {...field}
              value={field.value?.toLowerCase()}
              inputRef={ref}
              error={!!errors?.email}
              fullWidth
              helperText={getTypeMessage("code", errors.code?.type, t)}
              disabled={isValidate}
              InputProps={{
                endAdornment: (
                  <Button
                    sx={{
                      background: "black",
                      borderRadius: "4px",
                      padding: "6px 0",
                      width: "100px",
                      "&:hover": { background: "black" },
                    }}
                    onClick={validate}
                  >
                    <Typography fontSize="1.2rem" color="white">
                      {t("check")}
                    </Typography>
                  </Button>
                ),
              }}
            />
          )}
        />
        {isValidate && (
          <Typography mt="0.4rem" fontSize="1.4rem" color={getColor("skyblue")}>
            {t("verification-completed")}
          </Typography>
        )}
        {isValidateMessage && (
          <Typography mt="0.4rem" fontSize="1.4rem" color="red">
            {isValidateMessage}
          </Typography>
        )}
      </Box> */}
      <Box display="flex" flexDirection="column" gap="0.6rem">
        <Box display="flex" gap="6px" px="16px" style={{ cursor: "pointer" }} component="label">
          <Controller
            control={control}
            rules={{ validate: (v) => v }}
            name="chekced"
            defaultValue={false}
            render={({ field: { value, onChange, ...field } }) => (
              <Checkbox checked={value} onChange={onChange} {...field} />
            )}
          />
          <Box display="flex" flexDirection="column" gap="8px" mt="0.3rem">
            <Typography
              fontSize="1.6rem"
              fontWeight="800"
              color={errors.chekced ? getColor("red") : watch("chekced") ? getColor("marineBlue") : getColor("gray700")}
            >
              {t("i-agree-to-the-privacy-policy")}*
            </Typography>
            <Typography
              fontSize="1.6rem"
              lineHeight="2rem"
              fontWeight="500"
              color={errors.chekced ? getColor("red") : watch("chekced") ? getColor("marineBlue") : "black"}
            >
              {t("subscribe-description")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Button variant="contained" color="marineBlue" sx={{ py: "20px", mt: "20px" }} onClick={handleSubmit(subscribe)}>
        <Typography fontSize="2.2rem" fontWeight="500" color="white">
          {t("submit")}
        </Typography>
      </Button>
      <Box display="flex" justifyContent="flex-end" my="4px">
        <Typography
          fontSize="1.8rem"
          fontWeight="500"
          color="#676767"
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={changePage}
        >
          {t("unsubscribe-click-here")}
        </Typography>
      </Box>
    </>
  );
}
