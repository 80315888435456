import {Box, useMediaQuery} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useRouter} from 'next/router';

import {useWebzinesQuery} from '@/queries/webzines';
import {useEffect, useState} from 'react';
import Select from '@/components/shared/Select';

export default function SelectMagazine({
                                           selectedIndex,
                                           setSelectedIndex,
                                           setPageIndex
                                       }:
                                           {
                                               selectedIndex?: number;
                                               setSelectedIndex?: (index: number) => void;
                                               setPageIndex?: (index: number) => void;
                                           }) {
    const {t} = useTranslation();
    const {
        asPath,
        push,
        query: {id},
    } = useRouter();
    const lgMatch = useMediaQuery((theme) => (theme as any).breakpoints.down("lg"), {noSsr: true});
    const {data} = useWebzinesQuery();
    const [index, setIndex] = useState(selectedIndex);
    const i = selectedIndex ?? index;
    const setI = setSelectedIndex ?? setIndex;

    useEffect(() => {
        if (asPath.includes("viewer") && i !== undefined && id !== data?.data?.[i]?._id) {
            if (setPageIndex) {
                setPageIndex(1);
            }
            push(`/viewer/${data?.data?.[i]?._id ?? ""}`);
        }
    }, [i, asPath, id]);

    useEffect(() => {
        if (id) {
            setIndex(data?.data.findIndex(({_id}) => _id === id));
        }
    }, [id, data]);

    // if (lgMatch)
    //     return (
    //         <Box width="100%" zIndex={1000}>
    //             <Box right="12px" >
    //                 {!asPath.includes("articles") && i !== undefined && (
    //                     <Select
    //                         options={
    //                             asPath.includes("viewer")
    //                                 ? data?.data
    //                                 .filter(({ url }) => !url)
    //                                 .map(({ publishedDate }, i) => ({
    //                                     value: i,
    //                                     label: `${publishedDate.split(".")[1].toLocaleUpperCase()} ${
    //                                         t(publishedDate.split(".")[0]) ?? publishedDate.split(".")[0].toUpperCase()
    //                                     }`,
    //                                 })) ?? []
    //                                 : data?.data.map(({ publishedDate }, i) => ({
    //                                 value: i,
    //                                 label: `${publishedDate.split(".")[1].toLocaleUpperCase()} ${
    //                                     t(publishedDate.split(".")[0]) ?? publishedDate.split(".")[0].toUpperCase()
    //                                 }`,
    //                             })) ?? []
    //                         }
    //                         value={i}
    //                         defaultValue={0}
    //                         onChange={(e) => setI?.(Number(e.target.value))}
    //                         sx={{ fontSize: "18px", fontWeight: 500 }}
    //                         MenuProps={{
    //                             sx: {
    //                                 maxHeight: "250px",
    //                             },
    //                         }}
    //                     />
    //                 )}
    //             </Box>
    //         </Box>
    //     );

    return (
        <Box position="relative" zIndex={9999}
        >

            <Box
                // position="absolute"
                sx={{zIndex: 9900,}}
            >
                <Select
                    options={
                        asPath.includes("viewer")
                            ? data?.data
                            .filter(({url}) => !url)
                            .map(({publishedDate}, i) => ({
                                value: i,
                                label: ` ${t(publishedDate.split(".")[0]) ?? publishedDate.split(".")[0].toUpperCase()}
                                ${publishedDate.split(".")[1].toUpperCase()}`,
                            })) ?? []
                            : data?.data.map(({publishedDate}, i) => ({
                            value: i,
                            label: ` ${t(publishedDate.split(".")[0]) ?? publishedDate.split(".")[0].toUpperCase()}
                            ${publishedDate.split(".")[1].toUpperCase()}`,
                        })) ?? []
                    }
                    value={i}
                    onChange={(e) => setI?.(Number(e.target.value))}
                    sx={{
                        fontSize: "1.8rem",
                        zIndex: 9900,
                    }}
                    MenuProps={{
                        sx: {
                            maxHeight: "250px",
                            zIndex: 9999

                        },
                    }}
                />
            </Box>
        </Box>
    );
};
