import { useEffect, useState } from "react";
import Dialog from "../shared/Dialog";
import { useTranslation } from "react-i18next";
import { Box, DialogProps, Typography } from "@mui/material";
import { getColor } from "@/utils/style";
import { useAuth } from "@/contexts/authContext";
import SubscribeForm from "./SubscribeForm";
import UnsubscribeForm from "./UnsubscribeForm";

export default function NonUserSubscribeDialog({
  onClose,
  bottomSheet = true,
  ...props
}: {
  onClose: () => void;
  bottomSheet?: boolean;
} & DialogProps) {
  const { t } = useTranslation();
  const { refreshInfo, info } = useAuth();
  const [step, setStep] = useState(0);

  useEffect(() => {
    setStep(0);
  }, [props.open]);

  useEffect(() => {
    if (step === 0) {
      setStep(info?.subscribe ? 2 : 0);
    }
  }, [info, step]);

  const subscribe = () => {
    refreshInfo();
    setStep(1);
  };

  const dissubscribe = () => {
    refreshInfo();
    setStep(3);
  };

  const getContent = () => {
    switch (step) {
      case 0:
        return <SubscribeForm submit={subscribe} changePage={() => setStep(2)} />;
      case 1:
        return (
          <>
            <Typography fontSize="2.4rem" color={getColor("skyblue")} fontWeight="800">
              {t("subscribed")}.
            </Typography>
            <Typography fontSize="1.8rem" lineHeight="2.2rem" fontWeight="500" color={getColor("black")}>
              {t("hyundai-newsletter-will-be-sent-to-your-registered-email-address")}
            </Typography>
          </>
        );
      case 2:
        return <UnsubscribeForm submit={dissubscribe} changePage={() => setStep(0)} />;
      case 3:
        return (
          <>
            <Typography fontSize="2.4rem" color={getColor("red")} fontWeight="800">
              {t("unsubscribed")}.
            </Typography>
            <Typography fontSize="1.8rem" lineHeight="2.2rem" fontWeight="500" color={getColor("black")}>
              {t("your-subscription-has-been-canceled")}
            </Typography>
          </>
        );
    }
  };

  const getButton = () => {
    switch (step) {
      case 0:
        // return [{ content: t("submit") ?? "", callback: subscribe }];
        return [];
      case 1:
        return [{ content: t("close") ?? "", callback: onClose }];
      case 2:
        // return [
        //   { content: t("back") ?? "", callback: onClose, bgcolor: getColor("gray500") },
        //   { content: t("unsubscribe") ?? "", callback: dissubscribe },
        // ];
        return [];
      case 3:
        return [{ content: t("close") ?? "", callback: onClose }];
      default:
        return [];
    }
  };

  return (
    <Dialog
      buttons={getButton()}
      title={step >= 2 ? t("unsubscribe") : t("subscribe")}
      onClose={onClose}
      bottomSheet
      {...props}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="1.6rem"
        sx={{ minWidth: { xs: "auto", md: "42rem" } }}
        width="100%"
        maxWidth="100%"
      >
        {getContent()}
      </Box>
    </Dialog>
  );
}
