import { getColor } from "@/utils/style";
import { ChevronRight, HomeOutlined, PermIdentityOutlined } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import DotyLogo from "../../assets/doty-logo.svg";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import SelectLanguage from "../SelectLanguage";
import { useAuth } from "@/contexts/authContext";

interface Props {
  setIsSubscribeOpen: (open: boolean) => void;
  close: () => void;
}

export default function HamburgerSidebar({ setIsSubscribeOpen, close }: Props) {
  const { t } = useTranslation();
  const { isLoggedIn, info, logout } = useAuth();

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      width="90vw"
      mt="80px"
      overflow="scroll"
      borderTop={`1px solid ${getColor("black")}`}
    >
      <Box p="24px" display="flex" flexDirection="column" gap="24px" borderBottom={`1px solid ${getColor("gray300")}`}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="2.2rem" fontWeight="800" color={getColor("marineBlue")}>
            {t("global-dealer-newletter")}
          </Typography>
          <Link href="/" onClick={close} shallow>
            <HomeOutlined sx={{ fill: "black", fontSize: "24px" }} />
          </Link>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap="12px" flexWrap="wrap">
          <Box display="flex" alignItems="center" gap="16px">
            {isLoggedIn ? (
              <>
                <Link href="/mypage" passHref>
                  <Box display="flex" alignItems="center" gap="6px">
                    <PermIdentityOutlined sx={{ fill: "black", fontSize: "20px" }} />
                    <Typography color={getColor("gray700")} fontSize="1.8rem">
                      {info?.nickname}
                    </Typography>
                  </Box>
                </Link>
                <Divider orientation="vertical" flexItem style={{ borderColor: getColor("gray500") }} />
                <Typography
                  color={getColor("gray700")}
                  fontSize="1.8rem"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    logout();
                    close();
                  }}
                >
                  {t("logout")}
                </Typography>
              </>
            ) : (
              <>
                {/* <Link href="/signin" onClick={close}>
                  <Typography color={getColor("gray700")} fontSize="1.8rem">
                    {t("login")}
                  </Typography>
                </Link>
                <Divider orientation="vertical" flexItem style={{ borderColor: getColor("gray500") }} />
                <Link href="/signup" onClick={close}>
                  <Typography color={getColor("gray700")} fontSize="1.8rem">
                    {t("joinUs")}
                  </Typography>
                </Link> */}
              </>
            )}
          </Box>
          {!isLoggedIn && (
            <Box display="flex" gap="2rem">
              <Box
                py="8px"
                px="14px"
                borderRadius="4px"
                bgcolor={info?.subscribe ? getColor("marineBlue") : "#e6eaef"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsSubscribeOpen(true);
                  close();
                }}
              >
                <Typography
                  fontSize="1.6rem"
                  color={info?.subscribe ? "white" : getColor("marineBlue")}
                  fontWeight="500"
                >
                  {t("subscribe")}
                </Typography>
              </Box>
              {/*<Link href="https://hyundai-doty.com/" passHref target={"_blank"}>*/}
              {/*  <DotyLogo width="30" height="auto" />*/}
              {/*</Link>*/}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        p="24px"
        pt="16px"
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="space-between"
        height="100%"
        bgcolor={getColor("ivory")}
      >
        <Box display="flex" width="100%" flexDirection="column" gap="20px">
          <Link href="/articles/event" passHref onClick={close}>
            <Box py="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize="2.2rem" fontWeight="500" color={getColor("black")}>
                {t("event")}
              </Typography>
              <ChevronRight fontSize="large" />
            </Box>
          </Link>
          <Link href="/articles/local-news" passHref onClick={close}>
            <Box py="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize="2.2rem" fontWeight="500" color={getColor("black")}>
                {t("local-news")}
              </Typography>
              <ChevronRight fontSize="large" />
            </Box>
          </Link>
          <Link href="/articles/hyundai-heritage" passHref onClick={close}>
            <Box py="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize="2.2rem" fontWeight="500" color={getColor("black")}>
                {t("hyundai-heritage")}
              </Typography>
              <ChevronRight fontSize="large" />
            </Box>
          </Link>
          <Link href="/articles/life-style" passHref onClick={close}>
            <Box py="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize="2.2rem" fontWeight="500" color={getColor("black")}>
                {t("life-style")}
              </Typography>
              <ChevronRight fontSize="large" />
            </Box>
          </Link>
          <Link href="/articles/brand-and-h--tech" passHref onClick={close}>
            <Box py="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize="2.2rem" fontWeight="500" color={getColor("black")}>
                {t("brand-h-tech")}
              </Typography>
              <ChevronRight fontSize="large" />
            </Box>
          </Link>
          <Link href="/articles/training" passHref onClick={close}>
            <Box py="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize="2.2rem" fontWeight="500" color={getColor("black")}>
                {t("training")}
              </Typography>
              <ChevronRight fontSize="large" />
            </Box>
          </Link>
          <Link href="/articles/wrc" passHref onClick={close}>
            <Box py="16px" display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontSize="2.2rem" fontWeight="500" color={getColor("black")}>
                {t("wrc")}
              </Typography>
              <ChevronRight fontSize="large" />
            </Box>
          </Link>
        </Box>
        <Box mt="40px">
          <SelectLanguage />
        </Box>
      </Box>
    </Box>
  );
}
