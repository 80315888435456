import { Box, Input } from "@mui/material";
import { Clear, Search as SearchIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { getColor } from "@/utils/style";
import { useState } from "react";
import { useRouter } from "next/router";

export default function Search({ close }: { close: () => void }) {
  const { t } = useTranslation();
  const router = useRouter();
  const [search, setSearch] = useState("");

  return (
    <Box
      position="absolute"
      height="calc(100vh - 100px)"
      width="100%"
      top="100%"
      left="0"
      zIndex="100000"
      onClick={close}
    >
      <Box width="100%" height="100%" style={{ background: "rgba(0, 0, 0, 0.6)" }}>
        <Box
          width="100%"
          height="100px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={getColor("ivory")}
          position="relative"
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="1300px"
            position="relative"
            sx={{ width: ["100%", "100%", "100%", "1300px"], mx: { xs: "16px", md: "0" } }}
          >
            <Box
              width="900px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py="0.4rem"
              px="2rem"
              borderBottom="1px solid #999999"
              sx={{ width: ["100%", "100%", "830px", "900px"], mx: { xs: "32px", md: "0" } }}
            >
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                disableUnderline
                style={{ fontSize: "2.6rem", width: "100%" }}
                placeholder={t("search") ?? ""}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    router.push(`/articles/search?query=${search}`);
                  }
                }}
              />
              <Box display="flex" alignItems="center" gap="2.8rem">
                <Box
                  display="flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    router.push(`/articles/search?query=${search}`);
                    close();
                  }}
                >
                  <SearchIcon sx={{ fontSize: "2.6rem", color: "black" }} />
                </Box>
              </Box>
            </Box>
            <Box
              position="absolute"
              right="0"
              top="50%"
              style={{ cursor: "pointer", transform: "translateY(-50%)" }}
              onClick={close}
            >
              <Clear sx={{ fontSize: "2.6rem" }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
